exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-creating-quizzes-for-his-medical-students-more-quickly-antony-thirion-s-feedback-on-quiz-wizard-tsx": () => import("./../../../src/pages/articles/Creating-quizzes-for-his-medical-students-more-quickly-Antony-Thirion-s-feedback-on-Quiz-Wizard.tsx" /* webpackChunkName: "component---src-pages-articles-creating-quizzes-for-his-medical-students-more-quickly-antony-thirion-s-feedback-on-quiz-wizard-tsx" */),
  "component---src-pages-articles-creer-ses-quiz-de-medecine-plus-rapidement-le-retour-d-antony-thirion-sur-quiz-wizard-tsx": () => import("./../../../src/pages/articles/Creer-ses-quiz-de-medecine-plus-rapidement-le-retour-d-Antony-Thirion-sur-Quiz-Wizard.tsx" /* webpackChunkName: "component---src-pages-articles-creer-ses-quiz-de-medecine-plus-rapidement-le-retour-d-antony-thirion-sur-quiz-wizard-tsx" */),
  "component---src-pages-articles-emelina-cucunuba-using-quiz-wizard-for-language-courses-tsx": () => import("./../../../src/pages/articles/Emelina-Cucunuba-using-Quiz-Wizard-for-language-courses.tsx" /* webpackChunkName: "component---src-pages-articles-emelina-cucunuba-using-quiz-wizard-for-language-courses-tsx" */),
  "component---src-pages-articles-emelina-cucunuba-utiliser-quiz-wizard-en-cours-de-langues-tsx": () => import("./../../../src/pages/articles/Emelina-Cucunuba-utiliser-Quiz-Wizard-en-cours-de-langues.tsx" /* webpackChunkName: "component---src-pages-articles-emelina-cucunuba-utiliser-quiz-wizard-en-cours-de-langues-tsx" */),
  "component---src-pages-articles-quiz-wizard-en-physique-chimie-le-retour-d-experience-de-genevieve-ponsonnet-tsx": () => import("./../../../src/pages/articles/Quiz-Wizard-en-Physique-Chimie-le-retour-d-expérience-de-Geneviève-Ponsonnet.tsx" /* webpackChunkName: "component---src-pages-articles-quiz-wizard-en-physique-chimie-le-retour-d-experience-de-genevieve-ponsonnet-tsx" */),
  "component---src-pages-articles-quiz-wizard-in-sciences-genevieve-ponsonnet-s-feedback-tsx": () => import("./../../../src/pages/articles/Quiz-Wizard-in-Sciences-Genevieve-Ponsonnet-s-feedback.tsx" /* webpackChunkName: "component---src-pages-articles-quiz-wizard-in-sciences-genevieve-ponsonnet-s-feedback-tsx" */),
  "component---src-pages-conditions-d-utilisation-tsx": () => import("./../../../src/pages/conditions-d-utilisation.tsx" /* webpackChunkName: "component---src-pages-conditions-d-utilisation-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politique-de-confidentialite-tsx": () => import("./../../../src/pages/politique-de-confidentialite.tsx" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

